import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Unfortunately everything I work on is currently behind a Non-Disclosure Agreement (NDA) so have nothing to show off! Maybe one day... In the mean time here are some fun design systems i've worked with and resources I constantly refer to.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.material.io"
        }}>{`Material Design`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.microsoft.com/en-us/fluentui#/"
        }}>{`Fluent UI`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://lawsofux.com"
        }}>{`Laws of UX`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.uxisnotui.com/"
        }}>{`UX is not UI`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      